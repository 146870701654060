import fetch from "@/api/fetch"

// getYqData
export function parkInfo(){
	return fetch({
		url:'/apg/industry/special/parkInfo',
		method:'get'
	})
}
// components
// 园区税收
export function parkTax(){
	return fetch({
		url: '/apg/industry/special/parkTax',
		method:'get'
	})
}
// 园区企业
export function parkCompany(){
	return fetch({
		url: '/apg/industry/special/parkCompany',
		method:'get'
	})
}
// 企业税产排名
export function companyProduction(page,limit){
	const data={page,limit}
	return fetch({
		url: '/apg/industry/special/companyProduction',
		method:'post',
		data
	})
}
export function companyTax(page,limit){
	return fetch({
		url: '/apg/industry/special/companyTax?page='+page+'&limit='+limit,
		method:'get'
	})
}