<template>
	<div id="box">
		<div class="box">
			<div class="one">
				<h1>园区概况</h1>
				<div class="onebox">
					<div class="oneitem" v-for="(item,i) in yuanqu" :key='i'>
						<div class="yuan" :style="{'background-color':item.color}">
							<img :src="item.img" />
						</div>
						<p>{{item.name}}</p>
						<p>{{item.value}}<span>{{item.unit}}</span></p>
					</div>
				</div>
			</div>
			<div class="two">
				<chartlin></chartlin>
			</div>
			<div class="three">
				<chartbar></chartbar>
			</div>
			<div class="four">
				<charbar2></charbar2>
			</div>
		</div>
	</div>
</template>

<script>
	import chartlin from "@/components/Characteristicpark/Charlin"
	import chartbar from "@/components/Characteristicpark/Charbar"
	import charbar2 from "@/components/Characteristicpark/Charbar2"
	import {parkInfo} from "@/api/Characteristicpark/Characteristicpark"
	export default {
		data() {
			return {
				yuanqu:[
					{name:'园区数量',value:'20',unit:'个',img:require('@/assets/img/园区.png'),color:'#FE768E'},
					{name:'占地面积',value:'3190.32',unit:'亩',img:require('@/assets/img/面积图.png'),color:'#826185'},
					{name:'商务面积',value:'4321370',unit:'万平方米',img:require('@/assets/img/面积2.png'),color:'#2DCC76'},
					{name:'企业数量',value:'3100',unit:'家',img:require('@/assets/img/企业.png'),color:'#4056BA'},
					{name:'本年产值',value:'36.69',unit:'亿元',img:require('@/assets/img/产值能耗.png'),color:'#6B8B90'},
					{name:'本年税收',value:'1.89',unit:'亿元',img:require('@/assets/img/税收规模.png'),color:'#F2972D'},
				]
			}
		},
		components:{
			chartlin,
			chartbar,
			charbar2
		},
    mounted() {
		  this.getYqData()
    }
    ,
    methods:{
        getYqData(){
          let _this =this
          parkInfo().then(res => {
            // console.log(res.data)
            if(res.data.data){
              let parkInfo = res.data.data;
              // _this.yuanqu[0].value=parkInfo.projectCount
              _this.yuanqu[1].value=parkInfo.coveredAreaCount
              _this.yuanqu[2].value=parkInfo.businessAreaCount
              _this.yuanqu[3].value=parkInfo.companyCount
              _this.yuanqu[4].value=parseFloat(parkInfo.productionCount/10000).toFixed(2)
              _this.yuanqu[5].value=parseFloat(parkInfo.taxCount/10000).toFixed(2)
            }

          }).catch(err => {
              console.log(err)
            })
        }
    }
	}
</script>

<style scoped="scoped" lang="less">
	.box{
		width: 100%;
		background-color: #f2f3f5;
		border-radius: 4px;
		min-height: 600px;
		.one{
			width: 100%;
			height: 200px;
			background-color: #FFFFFF;
			border-radius: 4px;
			padding: 4px;
			.onebox{
				width: 100%;
				height: 150px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				.oneitem{
					width: 90px;
					height: 144px;
					text-align: center;
					.yuan{
						width: 90px;
						height: 90px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
						img{
							width: 64%;
						}
					}
					p{
						margin-top: 4px;
            span{
              white-space: nowrap;
            }
					}
				}
			}
		}
		.two{
			width: 100%;
			height: 350px;
			margin-top: 20px;
		}
		.three{
			width: 100%;
			height: 350px;
			margin-top: 20px;
		}
		.four{
			width: 100%;
			min-height: 350px;
			margin-top: 20px;
		}
	}
</style>
