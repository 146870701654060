<template>
	<div class="boxx">
		<div ref='bar' class="bar"></div>
	</div>
</template>

<script>
	import {parkCompany} from "@/api/Characteristicpark/Characteristicpark"
	export default {
		data() {
			return {
        yqcount:[],
        yqlist:[],
        yqlist1:[],
			}
		},
    mounted() {
      this.getYqData()

    },
    methods:{
      getYqData(){
        let _this =this
        parkCompany().then(res => {
          //console.log(res)
          if(res.data.data){
            let parkInfo = res.data.data;
            parkInfo.forEach(item=>{
              if(item.tenantCount==0||item.tenantCount==null){
                _this.yqlist1.push(item.projectName)
              }else {
                _this.yqlist.push(item.projectName)
                _this.yqcount.push(item.tenantCount)
              }
            })

            _this.yqlist1.forEach(item=>{
              _this.yqlist.push(item)
              _this.yqcount.push(0)
            })
            let box = this.$refs.bar
            this.chart(box)
          }
        })
      },
			chart(box){
				var myChart = this.$echarts.init(box);
				var option;
        let _this =this
				option = {
					title: {
					    text: '园区企业（入驻企业）',
						textStyle:{
							color:"#333333",
							fontSize: 18,
						}
					},
				    xAxis: {
				        type: 'category',
              data:_this.yqlist,
						boundaryGap: true,
						axisLabel:{
						     interval:0,//横轴信息全部显示
						},

				    },
				    yAxis: {
				        type: 'value',
						name:'单位:家'
				    },
					dataZoom:[
						{
							type:'slider',
							xAxisIndex:0,
							bottom: 0,
							height:12,
							showDetail: false,
							startValue: 0,
							endValue:11,
							handleSize: '110%',
							handleStyle:{
								color:"#d3dee5",
							},
							textStyle:{
								color:"#fff"
							},
							borderColor:"#90979c"
						},
					],
				    series: [{
				       data:_this.yqcount,
				        type: 'bar',
						barWidth: 20,
						label: {
							show: true,
							position: "top"
						},
						itemStyle: {
						      color: {
							    type: 'linear',
							    x: 0,
							    y: 0,
							    x2: 0,
							    y2: 1,
							    colorStops: [
								{offset: 0, color: '#0ad0ef'},
								{offset: 1, color: '#0d75ec'}],
							    global: false // 缺省为 false
							}
						}
				    }]
				};

				option && myChart.setOption(option);

			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx{
		width: 100%;
		height: 100%;
		.bar{
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}

</style>
