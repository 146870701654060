<template>
	<div class="boxx">
		<h1>企业税产排名</h1>
		<div class="btm">
			<div class="left" v-if="chanSwitch">
        <div class="btn">
        	<div class="btnitem" >
        		<div></div>
        		<p>产值</p>
        	</div>
          <el-button class="el-button gd el-button--primary el-button--small" type="primary" @click="activegd(1)">更多</el-button>
        </div>
				<div class="item" v-for="(item,i) in chan" :key="i">
					<p class="name">{{item.name}}</p>
					<div class="bg">
						<div class="progress" :style="{'width':item.ratio}"></div>
						<p class="bgsz">{{item.value}}万元</p>
					</div>
				</div>
			</div>
			<div class="right" v-if="shuiSwitch">
        <div class="btn">
        	<div class="btnitem">
        		<div style="background-color:#A98889 ;"></div>
        		<p>税收</p>
        	</div>
          <el-button class="el-button gd el-button--primary el-button--small" type="primary" @click="activegd(2)">更多</el-button>
        </div>
				<div class="item" v-for="(item,i) in tax" :key="i">
					<p class="name">{{item.name}}</p>
					<div class="bg">
						<div class="progress" :style="{'width':item.ratio}"></div>
						<p class="bgsz">{{item.value}}万元</p>
					</div>
				</div>
			</div>
		</div>
    <el-dialog
      title="企业税产排名"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      >
      <el-table
          :data="tableData"
          border
          :cell-style="{'text-align':'center'}"
          :header-cell-style="{'text-align':'center','font-size':'16px','font-weight':'bold','color':'#333'}"
          style="width: 100%">
          <el-table-column
            prop="name"
            label="公司名称"
            >
          </el-table-column>
          <el-table-column
            prop="value"
            :label="labelName"
            >
          </el-table-column>
        </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
                     :page-size="limit" layout=" prev, pager, next, jumper,total" :total="count">
      </el-pagination>
    </el-dialog>
	</div>
</template>

<script>
	import {companyProduction,companyTax} from "@/api/Characteristicpark/Characteristicpark"
	export default {
		data() {
			return {
				chan:[
					{name:"新雅工业园区10",value:'561368',ratio:'100%'},
					{name:"新雅工业园区1",value:'561368',ratio:'90%'},
					{name:"新雅工业园区2",value:'5613',ratio:'80%'},
					{name:"新雅工业园区3",value:'561',ratio:'70%'},
					{name:"新雅工业园区4",value:'561368',ratio:'60%'},
					{name:"新雅工业园区5",value:'561368',ratio:'50%'},
					{name:"新雅工业园区6",value:'561368',ratio:'40%'},
					{name:"新雅工业园区7",value:'561368',ratio:'30%'},
					{name:"新雅工业园区8",value:'561368',ratio:'20%'},
					{name:"新雅工业园区9",value:'561368',ratio:'10%'},
				],
        tax:[
          {name:"新雅工业园区10",value:'561368',ratio:'100%'},
          {name:"新雅工业园区1",value:'561368',ratio:'90%'},
          {name:"新雅工业园区2",value:'5613',ratio:'80%'},
          {name:"新雅工业园区3",value:'561',ratio:'70%'},
          {name:"新雅工业园区4",value:'561368',ratio:'60%'},
          {name:"新雅工业园区5",value:'561368',ratio:'50%'},
          {name:"新雅工业园区6",value:'561368',ratio:'40%'},
          {name:"新雅工业园区7",value:'561368',ratio:'30%'},
          {name:"新雅工业园区8",value:'561368',ratio:'20%'},
          {name:"新雅工业园区9",value:'561368',ratio:'10%'},
        ],
				chanSwitch:true,
				shuiSwitch:true,
        dialogVisible:false,
        count:0,
        activeid:1,
        tableData:[],
        labelName:'产值(万元)',
        limit:10,
        page:1

			}
		},
		mounted() {
      this.getChanData()
      this.getTaxData()
		},
		methods:{
      handleSizeChange(val) {
        this.limit =val
        this.getData(this.activeid)
       // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page=val
        this.getData(this.activeid)
       // console.log(`当前页: ${val}`);
      },
      handleClose(done) {
        this.dialogVisible = false
        this.page=1
        this.limit=10
      },
      getChanData(){

        let _this = this
		companyProduction(_this.page,_this.limit).then(res=> {
          console.log(res.data.data.list)
          _this.count=res.data.data.count
          if(res.data.data.list){
             let chanData=res.data.data.list
            let index=0
            _this.chan=[]
            chanData.forEach(item=>{
              _this.chan.push({
                name:item.tenantName,
                value:item.production,
                ratio:(10-index)*10+'%'
              })
              index++
            })
          }
        })

      },
      getTaxData(){

        let _this = this
		companyTax(_this.page,_this.limit).then(res=> {
          //console.log(res.data.data.list)
          _this.count=res.data.data.count
          if(res.data.data.list){
            let taxData=res.data.data.list
            let index=0
            _this.tax=[]
            taxData.forEach(item=>{
              _this.tax.push({
                name:item.tenantName,
                value:item.tenantTax,
                ratio:(10-index)*10+'%'
              })
              index++
            })
          }
        })

      },
      getData(val){
        let url=''
        if (val==1){
          url = `/apg/industry/special/companyProduction`
        }else {
          url = `/apg/industry/special/companyTax`
        }
        this.$axios({
          url:url,
          method:'get',
          data: {}, // body参数
          params:{limit: this.limit,page: this.page} // query参数
        }).then(res=> {
          //console.log(res.data.data.list)
          this.count=res.data.data.count
          if(res.data.data.list){
            let taxData=res.data.data.list
            this.tableData=[]
            taxData.forEach(item=>{
              if(val==1){
                  this.labelName= '产值(万元)'
                  this.tableData.push({
                  name:item.tenantName,
                  value:item.production
                })
              }else {
                this.labelName= '税收(万元)'
                this.tableData.push({
                  name:item.tenantName,
                  value:item.tenantTax
                })
              }

            })
          }
        })
      },
      activegd(val){
        this.activeid=val
        this.dialogVisible=true
        this.getData(val)
      },
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx{
		width: 100%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 4px;
		padding: 4px;
		position: relative;
		.btn{
			position: absolute;
			right: 17px;
			top: -38px;
			display: flex;
			align-items: center;
			width: 167px;
			.btnitem{
				width: 61px;
				height: 26px;
				display: flex;
				align-items: center;
				margin: 0 10px;
				div{
					width: 29px;
					height: 20px;
				}
			}
			.btnitem:nth-child(1){
				div{
					background-color: #739C65;
				}
			}
			.btnitem:nth-child(2){
				div{
					background-color: #A98889;
				}
			}
			.btnitem:hover{
				cursor: pointer;
			}
		}


		.btm{
			width: 100%;
			height: 92%;
			margin-top: 13px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.left{
				width: 49%;
				height: 100%;
        position: relative;
				.item{
					width: 100%;
					height: 36px;
					display: flex;
					align-items: center;
					margin: 2px 0;
					.name{
						width: 34%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.bg{
						width: 60%;
						height: 60%;
						background-color: #F2F3F5;
						position: relative;
						.progress{
							height: 100%;
							background-color: #739C65;
							border-radius: 0 14px 14px 0;
							transition: width 0.3s;
						}
						.bgsz{
							width: 30%;
							height: 100%;
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							margin:  auto;
						}
					}
				}
			}
			.right{
				width: 49%;
				height: 100%;
        position: relative;
				.item{
					width: 100%;
					height: 36px;
					display: flex;
					align-items: center;
					margin: 2px 0;
					.name{
						width: 34%;
					}
					.bg{
						width: 60%;
						height: 60%;
						background-color: #F2F3F5;
						position: relative;
						.progress{
							height: 100%;
							background-color: #A98889;
							border-radius: 0 14px 14px 0;
							transition: width 0.3s;
						}
						.bgsz{
							width: 30%;
							height: 100%;
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							margin:  auto;
						}
					}
				}
			}
		}
	}
</style>
