<template>
	<div class="boxx">
		<div ref="line" class="line"></div>
		<!--<div class="select">
			<el-select v-model="value" clearable placeholder="请选择园区" @change="select">
			    <el-option
			      v-for="item in options"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
		</div>-->
	</div>
</template>

<script>
	import {parkTax} from "@/api/Characteristicpark/Characteristicpark"
	export default {
		data() {
			return {
				options: [{value: '1',label: '新雅工业园区'},
				{value: '2',label: '金田工业园'},
				{value: '3',label: '仪帮工业园'},
				{value: '4',label: '曙光工业园'},
				{value: '5',label: '龙港（天成）时尚小微园'}],
				value: '',
        yqlist:[],
        yqdata:[],
        yqlist1:[]
			}
		},
		mounted() {
      this.getYqData()
		},
		methods:{
      getYqData(){
        let _this =this
        parkTax().then(res => {
          // console.log(res)
          if(res.data.data){
            let parkInfo = res.data.data;
            parkInfo.forEach(item=>{
              if(item.tenantTax==0||item.tenantTax==null){
                _this.yqlist1.push(item.projectName)
              }else {
                _this.yqlist.push(item.projectName)
                  _this.yqdata.push(item.tenantTax)
              }


              })
            _this.yqlist1.forEach(item=>{
              _this.yqlist.push(item)
              _this.yqdata.push(0)
            })
            var box = this.$refs.line
            this.chart(box)
          }
        }).catch(err => {
          console.log(err)
        })
      },
			chart(box){
				var myChart = this.$echarts.init(box);
				var option;
        let _this=this
          option = {
            title: {
              text: '园区税收',
              textStyle:{
                color:"#333333",
                fontSize: 18,
              }
            },
            xAxis: {
              type: 'category',
              data: _this.yqlist,
              boundaryGap: true,
              axisLabel:{
                interval:0,//横轴信息全部显示
                formatter:function(val){
                	var strs = val.split(''); //字符串数组
                	var str = ''
                	for(var i = 0, s; s = strs[i++];) { //遍历字符串数组
                		str += s;
                		if(!(i % 6)) str += '\n'; //按需要求余
                	}
                	return str
                }
              },
            },
            yAxis: {
              type: 'value',
              name: "单位:万元"
            },
            dataZoom:[
              {
                type:'slider',
                xAxisIndex:0,
                bottom: 0,
                height:12,
                showDetail: false,
                startValue: 0,
                endValue:11,
                handleSize: '110%',
                handleStyle:{
                  color:"#d3dee5",
                },
                textStyle:{
                  color:"#fff"
                },
                borderColor:"#90979c"
              },
            ],
            series: [{
              data: _this.yqdata,
              type: 'bar',
              barWidth: 20,
              label: {
                show: true,
                position: "top"
              },
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: '#85d057'},
                    {offset: 1, color: '#84c5cb'},
                     ],
                  global: false // 缺省为 false
                }
              }
            }]
          };


				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx{
		width: 100%;
		height: 100%;
		position: relative;
		.line{
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
		.select{
			position: absolute;
			z-index: 1;
			right: 4px;
			top: 4px;
		}
	}
</style>
